.hamburger-menu {
  width: 35px;
  height: 30px;
  // background-color: var(--color-red);
  position: fixed;
  top: 50px;
  right: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // border-radius: 10% 0 0 70%;
  // overflow: hidden;
}

.line {
  width: 100%;
  height: 3px;
  background-color: chocolate;
  transition: all .8s;
}

.open .line-1 {
  transform: rotateZ(-405deg) translate(-8px, 6px);
}

.open .line-2 {
  opacity: 0;
}

.open .line-3 {
  transform: rotateZ(405deg) translate(-8px, -6px);
}