.aside {
  background-color: var(--bg-light);
  border-right: 1px solid var(--border-light);
  padding: 3rem;
  width: 27rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: -27rem;
  z-index: 10;

  .logo {
    // background-color: var(--bg-dark);
    padding: 3rem 0;

    a {
      font-size: 2.8rem;
      font-family: var(--font-barcode);
      color: var(--heading);
      text-decoration: none;
      display: inline-block;
      position: relative;
      padding: 0 1rem;
    }

    // Logo top left corner
    a::before {
      border-top: 4px solid var(--accent);
      border-left: 4px solid var(--accent);
      content: "";
      height: 2rem;
      width: 2rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    // Logo bottom right corner
    a::after {
      border-bottom: 4px solid var(--accent);
      border-right: 4px solid var(--accent);
      content: "";
      height: 2rem;
      width: 2rem;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .logo:hover {
    position: relative;

    .laser {
      width: 110%;
      margin-left: -5%;
      background-color: var(--color-red);
      height: 1px;
      position: absolute;
      top: 25%;
      z-index: 2;
      box-shadow: 0 0 1rem var(--color-red);
      animation: scanning 2s infinite;
    }

    .diode {
      animation: beam 0.02s infinite;
    }
  }

  // list
  .nav {
    // background-color: var(--bg-dark);
    list-style: none;
    margin: 8rem 0;

    li {
      // background-color: var();
      display: block;

      a {
        display: block;
        font-size: 2rem;
        font-weight: 600;
        line-height: 4.5rem;
        text-decoration: none;
        text-transform: capitalize;
        color: var(--text);
        border-bottom: 1px solid var(--border-light);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;

        // Icons
        .fa {
          padding-right: 1.5rem;
        }
      }

      a:not(.active):hover {
        padding-left: 0.5rem;
        color: var(--accent);
      }

      a.active {
        color: var(--accent);
      }
    }
  }

  .copyright {
    // background-color: var(--bg-dark);
    color: #7d7d7d;
    font-size: 1.3rem;
  }

  .nav-toggler {
    background-color: var(--bg-light);
    height: 4rem;
    width: 4.5rem;
    border: 1px solid var(--border-light);
    border-radius: 0.5rem;
    cursor: pointer;
    position: fixed;
    top: 3rem;
    left: 30rem;
    z-index: 11;

    // display: none;
    display: flex;
    align-items: center;
    justify-content: center;

    // hamburger center line
    span {
      background-color: var(--accent);
      height: 0.2rem;
      width: 1.8rem;
      display: inline-block;
      position: relative;
    }

    span::before {
      content: "";
      background-color: var(--accent);
      position: absolute;
      height: 0.2rem;
      width: 1.8rem;
      top: -0.6rem;
      left: 0;
    }

    span::after {
      content: "";
      background-color: var(--accent);
      position: absolute;
      height: 0.2rem;
      width: 1.8rem;
      top: 0.6rem;
      left: 0;
    }
  }
}

.aside .open {
  right: 0;
}
