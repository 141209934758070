.section {
  background-color: var(--bg-dark);
  min-height: 100vh;
  display: block;
  padding: 0 3rem;
}

.main-content {
  // background-color: var(--color-blue);
  // padding-left: 27rem;
}

.container {
  max-width: 110rem;
  width: 100%;
  margin: auto;
  // background-color: var(--color-red);
}

.home {
  // background-color: var(--color-blue);
  background: var(--landing-img);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;

  .intro {
    text-align: center;

    .profile-pic {
      background-color: var(--color-grey);
      background-image: url('../img/selfie.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 25rem;
      height: 25rem;
      border-radius: 50%;
      display: inline-block;
      border: .8rem solid var(--bg-light);
    }

    .profile-pic:hover {
      background: url('../img/ted.gif');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      cursor: help;
    }

    // This is hover animation for the Ted profile card
    // .profile-pic:hover ~ p{
    //   visibility: hidden;
    // }

    // .profile-pic:hover ~ p::after{
    //   visibility: visible;
    //   color: aqua;
    //   content: 'Jack of all trades, master of fun';
    //   position: absolute;
    //   transform: translateX(-100%);
    // }

    // .profile-pic:hover ~ p > span {
    //   visibility: hidden;
    //   position: relative;
    // }

    // .profile-pic:hover ~ p > span:after {
    //   visibility: visible;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   content: 'Big Teddy Bear';
    //   color: aqua;
    // }

    h1 {
      font-size: 3rem;
      color: var(--color-white);
      font-family: var(--font-primary);
      font-weight: 700;
      margin: 2rem 0 .5rem;
    }

    p {
      font-size: 2rem;
      font-weight: 500;
      margin: 0;
      line-height: 2.2rem;
      color: var(--color-white);
    }

    .social-links {
      margin-top: 2.5rem;

      a {
        background-color: var(--accent);
        color: var(--color-white);
        font-size: 3.2rem;
        height: 4.5rem;
        width: 4.5rem;
        line-height: 4.5rem;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin: 0 .4rem;
        text-decoration: none;
        transition: all .3s ease;
      }

      a:hover {
        transform: scale(1.1);
      }
    }
  }
}


.typing {
  color: var(--accent);
  font-weight: 600;
}