
// Scan gun animation - https://codepen.io/fusco/pen/RNJQQN
@keyframes beam {
  50% {
    opacity: 0;
  }
}

@keyframes scanning {
  50% {
    transform: translateY(5rem);
  }
}