// ========================================
// SCSS VARIABLES - good for media queries using em's, but better to use native css variables for everything else
// $bp-phone: 37.5em; // 600px / 16px = 37.5em
// $bp-tab-port: 56.25em; // 900px / 16px = 56.25em
// $bp-tab-land: 75em; // 1200px / 16px = 75em
// $bp-big-desktop: 112.5em; // 1800px / 16px = 112.5em

:root {  // Declare Vanilla CSS variables inside the :root pseudo-class
  
  // ========================================
  // FONTS
  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Dosis", sans-serif;
  --font-barcode: 'Libre Barcode 39 Text', cursive;

  // ========================================
  // COLORS
  --color-white: #fff;
  --color-black: #000;
  --color-red: #f44336;
  --color-blue: #2196f3;
  --color-pink: #e91e63;
  --color-purple: #9c27b0;
  --color-indigo: #3f51b5;
  --color-green: #4caf50;
  --color-orange: #ff9800;
  --color-brown: #795548;
  --color-grey: #9e9e9e;
  --color-grey-lighten-5: #fafafa;
  --color-grey-lighten-4: #f5f5f5;
  --color-grey-lighten-3: #eeeeee;
  --color-grey-lighten-2: #e0e0e0;
  --color-grey-lighten-1: #bdbdbd;
  --color-grey-darken-1: #757575;
  --color-grey-darken-2: #616161;
  --color-grey-darken-3: #424242;
  --color-grey-darken-4: #212121;

  // Tutorial colors
  // ========================================
  // Dark theme
  --dark-bg-dark: #151515; // background dark
  --dark-bg-light: #222222; // background light
  --dark-border: #393939; // border
  --dark-text: #e9e9e9; // text
  --dark-heading: #ffffff; // heading

  // Light Theme
  --light-bg-dark: #f2f2fc; // background dark
  --light-bg-light: #fdf9ff; // background light
  --light-border-dark: #d4d4e3; // border dark
  --light-border-light: #e8dfec; // border light
  --light-text: #504e70; // text
  --light-heading: #302e4d; // heading

  // Theme skins
  --clr-pink: #ec1839;
  --clr-blue: #2196f3;
  --clr-orange: #fa5b0f;
  --clr-yellow: #ffb400;
  --clr-green: #72b626;


  // Default Light Theme - Should reference colors declared above
  --bg-dark: var(--light-bg-dark);
  --bg-light: var(--light-bg-light);
  --heading: var(--light-heading);
  --text: var(--light-text);
  --border-light: var(--light-border-light);
  --border-dark: var(--light-border-dark);
  --accent: var(--clr-orange);
  --landing-img: url(../img/balloon.jpg);


}

.dark-mode {
  --bg-dark: var(--dark-bg-dark);
  --bg-light: var(--dark-bg-light);
  --heading: var(--dark-heading);
  --text: var(--dark-text);
  --border-light: var(--dark-border);
  --border-dark: var(--dark-border);
  --accent: var(--clr-orange);
  --landing-img: url(../img/sunset-africa.jpg);
}

// Accents
.style-pink {
  --accent: var(--clr-pink);
}
.style-blue {
  --accent: var(--clr-blue);
}
.style-orange {
  --accent: var(--clr-orange);
}
.style-yellow {
  --accent: var(--clr-yellow);
}
.style-green {
  --accent: var(--clr-green);
}

// .solar {
//   --text: var(--color-blue);
// }