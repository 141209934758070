* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = (10px / 16px = 62.5%)
  height: 100vh;
  background-color: var(--color-red);
}

body {
  // overscroll-behavior-y: none;
  // overscroll-behavior-x: none;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.5;
  color: var(--text);

  background-image: linear-gradient(
    to bottom,
    var(--color-grey-darken-3),
    var(--color-grey-darken-4)
  );
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  // Theme change transition
  transition: background-color 500ms ease-in-out, color 1000ms ease-in-out;
}