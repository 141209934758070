.style-switcher {
  background-color: var(--bg-light);
  position: fixed;
  top: 2rem;
  right: 0;
  z-index: 10;
  padding: 1.5rem;
  border: 1px solid var(--border-light);
  border-right: none;
  border-radius: 0 0 0 .5rem;
  transform: translateX(100%);
  transition: all .3s ease;

  h5 {
    font-size: 1.6rem;
    color: var(--text);
    margin-top: 0;
    margin-bottom: .8rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--border-dark);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    margin-bottom: .5rem;

    li {
      display: inline-block;

      a {
        height: 3rem;
        width: 3rem;
        display: block;
        border-radius: 50%;
        cursor: pointer;
        text-decoration: none;
        margin-right: .2rem;
      }
    }
  }

  .toggle-style-switcher {
    position: absolute;
    height: 4.5rem;
    width: 4.5rem;
    background: var(--bg-light);
    left: -4.6rem;
    top: -.1rem;
    border: 1px solid var(--border-light);
    border-right: none;
    text-align: center;
    cursor: pointer;
    border-radius: .5rem 0 0 .5rem;

    .fa {
      font-size: 2rem;
      line-height: 4.3rem;
    }
  }
}

.style-switcher.open {
  transform: translateX(0%);
}

#dark-mode-toggle {
  padding: .5rem 2rem;
}